import { HttpStatusCode } from "axios";
import { getRequest } from "../api";
import { certificateAPI } from "../api/endPoints";
import { Certificate } from "../models/Certificate.Interface";

export async function getCertificate(
  certificateId: string
): Promise<Certificate> {
  try {
    const result = await getRequest(certificateAPI(certificateId));
    if (result.status === HttpStatusCode.Ok) {
      return result.data.data as Certificate;
    } else {
      throw new Error("Certificate not found");
    }
  } catch (err: any) {
    throw new Error("Certificate not found");
  }
}
