import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DiplomaPage } from "./components/DiplomaPage";
import { Custom404 } from "./components/Custom404";

export const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="confirm/:certificateId" element={<DiplomaPage />} />
        <Route path="not-found" element={<Custom404 />} />
        {/* Redirect all other routes to Custom404, in v6, you handle this with a "*" path */}
        <Route path="*" element={<Custom404 />} />
      </Routes>
    </Router>
  );
};
