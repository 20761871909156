import React from "react";
import styles from "./custom.module.css";

export const Custom404: React.FC = () => {
  const redirectToCircles = () => {
    window.open("https://www.getcircles.org", "_blank", "noopener,noreferrer");
  };

  return (
    <div className={styles.App}>
      <div className={styles.header}>
        <a
          href="https://www.getcircles.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/circles_logo.svg"
            alt="Circles Logo"
            className={styles.logo}
          />
        </a>
      </div>
      <div className={styles.content}>
        <div className={styles.leftColumn}>
          <img
            className={styles.certificateImg}
            src="/certificate-icon.svg"
            alt="certificate"
          />
          <div className={styles.noCertificateTagline}>
            We are unable to find a certificate at this link
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.circlesInfo}>
            <div className={styles.circlesTagline}>
              <span>
                Develop 21<sup>st</sup> Century <br />
                Skills with Circles
              </span>
            </div>
            <div className={styles.circlesDescription}>
              Circles is a provider of distance learning with a twist. Here,
              students have an active learning experience learning alongside
              friends they meet within the platform.
            </div>
            <button onClick={redirectToCircles} className={styles.button}>
              Visit Circles
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
